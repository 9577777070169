import axios from 'axios';
const { MS_LULLA_API_URL } = require('~config/default.env').default;
const baseURL = MS_LULLA_API_URL;

// CHILD TRACKER
// const childTrackerCount = () => axios.get('child-tracker/count', { baseURL });
const childTracker = (params) =>
  axios.get('child-tracker', { baseURL, params });
// const childTrackerDetail = ({ id }) => axios.get(`child-tracker/${id}`, { baseURL });
// const createChildTracker = (payload) => axios.post('child-tracker', payload, { baseURL});

// NUTRITION
const nutritionHeading = (params) =>
  axios.get('nutrition-heading', { baseURL, params });
// const nutritionListsCount = () => axios.get('nutrition-lists/count', { baseURL });
const nutritionLists = (params) =>
  axios.get('nutrition-lists', { baseURL, params });
const nutritionListsDetail = ({ id, params }) =>
  axios.get(`nutrition-lists/${id}`, { baseURL, params });

// PRODUCT RECOMMENDATION
// const productRecommendationCount = () => axios.get('product-recommendation/count', { baseURL });
const productRecommendation = (params) =>
  axios.get('product-recommendation', { baseURL, params });

// FAQS
// const faqsCount = () => axios.get('faqs/count', { baseURL });
const faqs = (params) => axios.get('faqs', { baseURL, params });
const faqDetail = ({ id }) => axios.get(`faq/${id}`, { baseURL });
// const createFaqs = (payload) => axios.post('faqs', payload, { baseURL });
// const updateFaq = ({ id, payload }) => axios.put(`faq/${id}`, payload, { baseURL });
// const deleteFaq = ({ id }) => axios.delete(`faq/${id}`, { baseURL });

const sharedFaq = (payload) => {
  return axios.post(`${MS_LULLA_API_URL}/faqs/shared-history`, payload);
};

const getSharedFaq = (params) => {
  return axios.get(`${MS_LULLA_API_URL}/faqs/shared-history`, {
    params,
  });
};

const updateUserPoints = (payload) => {
  return axios.put(`${MS_LULLA_API_URL}/faqs/points`, payload);
};

const getBabyRegistry = (params) => {
  return axios.get(`${MS_LULLA_API_URL}/baby-registry`, {
    params,
  });
};

const getBabyRegistryCount = (params) => {
  return axios.get(`${MS_LULLA_API_URL}/baby-registry/count`, {
    params,
  });
};

const addToBagBabyRegistry = (payload) => {
  return axios.post(`${MS_LULLA_API_URL}/baby-registry`, payload);
};

const updateBabyRegistry = ({ payload, id }) => {
  return axios.put(`${MS_LULLA_API_URL}/baby-registry/${id}`, payload);
};

const deleteBabyRegistry = (id) => {
  return axios.delete(`${MS_LULLA_API_URL}/baby-registry/${id}`);
};

const getTotalBabyRegistry = (params) => {
  return axios.get(`${MS_LULLA_API_URL}/baby-registry/count`, { params });
};

const deleteProductBabyRegistry = (payload) => {
  return axios.delete(`${MS_LULLA_API_URL}/baby-registry/product`, {
    data: payload,
  });
};

const getMomChecklist = (params) => {
  return axios.get(`${MS_LULLA_API_URL}/moms-checklist`, { params });
};

const getMomChecklistTotal = (params) => {
  return axios.get(`${MS_LULLA_API_URL}/moms-checklist/count`, { params });
};

const addMomChecklistProducts = ({ payload, id }) => {
  return axios.post(`${MS_LULLA_API_URL}/moms-checklist/item/${id}`, payload);
};

const addMomChecklistNew = (payload) => {
  return axios.post(`${MS_LULLA_API_URL}/moms-checklist`, payload);
};

const deleteMomChecklist = (id) => {
  return axios.delete(`${MS_LULLA_API_URL}/moms-checklist/${id}`);
};

const deleteMomChecklistItem = ({ id, subId }) => {
  return axios.delete(`${MS_LULLA_API_URL}/moms-checklist/${id}/item/${subId}`);
};

const updateHiddenMomChecklist = ({ isHidden, id }) => {
  return axios.put(`${MS_LULLA_API_URL}/moms-checklist/${id}`, {
    is_hidden: isHidden,
  });
};

const addMomChecklistItem = ({ payload, id }) => {
  return axios.post(`${MS_LULLA_API_URL}/moms-checklist/${id}/item`, payload);
};

const updateMomChecklistItem = ({ payload, id }) => {
  return axios.put(`${MS_LULLA_API_URL}/moms-checklist/${id}/item`, payload);
};

const updateMomChecklistNew = ({ payload, id }) => {
  return axios.put(`${MS_LULLA_API_URL}/moms-checklist/${id}`, payload);
};

const addProductRecommendationsMomChecklist = ({ payload, id, subId }) => {
  return axios.post(
    `${MS_LULLA_API_URL}/moms-checklist/${id}/item/${subId}`,
    payload,
  );
};

const updateProductRecommendationsMomChecklist = ({ payload, id, subId }) => {
  return axios.put(
    `${MS_LULLA_API_URL}/moms-checklist/${id}/item/${subId}`,
    payload,
  );
};

const updateProductRecomendationChecked = ({ checklistItemId, payload }) => {
  return axios.put(
    `${MS_LULLA_API_URL}/moms-checklist/${checklistItemId}/checked`,
    payload,
  );
};

export default {
  // child-tracker
  // childTrackerCount, // unused
  childTracker,
  // childTrackerDetail, // unused
  // createChildTracker, // unused
  // nutritions
  nutritionHeading,
  // nutritionListsCount, // unused
  nutritionLists,
  nutritionListsDetail,
  // recommendation
  // productRecommendationCount, // unused
  productRecommendation,
  // faqs
  // faqsCount, // unused
  faqs,
  faqDetail,
  sharedFaq,
  getSharedFaq,
  updateUserPoints,
  getBabyRegistry,
  addToBagBabyRegistry,
  updateBabyRegistry,
  deleteBabyRegistry,
  getTotalBabyRegistry,
  deleteProductBabyRegistry,
  getBabyRegistryCount,

  getMomChecklist,
  getMomChecklistTotal,
  addMomChecklistProducts,
  addMomChecklistNew,
  deleteMomChecklist,
  deleteMomChecklistItem,
  updateHiddenMomChecklist,
  addMomChecklistItem,
  updateMomChecklistItem,
  updateMomChecklistNew,
  addProductRecommendationsMomChecklist,
  updateProductRecommendationsMomChecklist,
  updateProductRecomendationChecked,
};
